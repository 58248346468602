import React, { useState, useEffect } from "react";
import { LOGO_WHITE } from "../../svgs";
import { Link } from "react-router-dom";
import Form from "./Form";

import "./index.css";

export default function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const listener = () => {
    setScreenWidth(window.innerWidth);
  };
  console.log(screenWidth);
  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);
  return (
    <div>
      <div>
        <div className="items-end content-end flex flex-row-reverse">
          {window.innerWidth > 1024 ? (
            <img
              className="h-3/4 w-auto  -mt-40 -mb-40 z-10 mr-0 ml-6"
              src={require("../../../src/images/BasketballPlayer.png")}
              alt=""
            />
          ) : null}
        </div>
        <div
          className="
         flex flex-col items-center justify-center text-center p-4 sm:p-8 relative"
        >
          <h4 className="Footer_title-small mt-6">Our Partners</h4>
          <div className="p-0 mx-0">
            <img
              className="h-100 mt-4 md:p-12 mx-0"
              src={require("../../../src/images/Partners.png")}
              alt=""
            />
          </div>

          <div id="contact" className="Footer_line-blue p-8 items-center">
            <img
              className=" h-full w-auto mx-auto"
              src={require("../../../src/images/logoPartners.svg")}
              alt="Iptlogo"
            />
          </div>

          <div className="text-left  flex flex-col justify-center items-center  z-0">
            <h4 className="Footer_title mt-6">Are you ready to play?</h4>
            <p className="Footer_subtitle mb-2 lg:w-2/4 mt-4 text-center mb-4">
              Learn more about Inside The Pocket’s free-to-play sports gaming
              platform, and how our unique aggregator model can build new
              audience opportunities for your organisation.
            </p>
            <Form />
          </div>
        </div>
        <div className="Footer_line"></div>
        <div className="bg-footer_blue">
          <div className="footer-bottom flex flex-col  text-center md:flex-row md:justify-between   p-6 main_div">
            <div className="flex flex-col items-center md:text-left md:items-start">
              <img
                src={LOGO_WHITE}
                alt="logo"
                className="mb-2 w-1/3 md:w-full md:text-left"
              />
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/terms-and-conditions">Terms and Conditions</a>
            </div>

            <div className="flex flex-col items-end md:text-right">
              <p className="footer-rights md:items-end">
                <div className="flex flex-col items-center md:flex-row-reverse md:items-end">
                  <a
                    href="https://www.linkedin.com/company/inside-the-pocket-limited/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className=" md:-mb-2 md:ml-2"
                      src={require("../../../src/images/linkedin.png")}
                      alt=""
                    />
                  </a>
                  <p className="md:text-right">
                    {" "}
                    We are social. Contact us on{" "}
                    <a
                      href="https://www.linkedin.com/company/inside-the-pocket-limited/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn{" "}
                    </a>
                  </p>
                </div>
                <br></br>
                Inside The Pocket. All rights reserved. Inside The Pocket
                Limited (company registration number 12607772). This site uses
                cookies, for more information please see our&nbsp;
                <Link to="/privacy-policy" className="font-black">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
